<button (click)="buttonClick.emit($event);" *ngIf="type === 'raised'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        [class.rounded-md]="!rounded"
        [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" mat-raised-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event);" *ngIf="type === 'stroked'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        [class.rounded-md]="!rounded"
        [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" mat-stroked-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === 'flat'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        [class.rounded-md]="!rounded"
        [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" mat-flat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === 'icon'" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        [class.rounded-md]="!rounded"
        [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" mat-icon-button>
    <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
</button>

<button (click)="buttonClick.emit($event)" *ngIf="type === '' || type == null" [class]="buttonClass" [color]="color"
        [disabled]="disabled"
        [class.rounded-md]="!rounded"
        [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" mat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>

<ng-template #buttonContent class="flex items-center">
    <div class="flex items-center gap-2 text-sm sm:text-md">
        <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
        <span [ngClass]="{'mt-0.5 text-md': size == 'medium', 'mt-0.5 text-sm': size == 'small'}">
            <ng-content></ng-content>
        </span>
    </div>
</ng-template>

<ng-template #buttonIconContent>
    <mat-icon *ngIf="icon && iconLib === 'mat'" [color]="color" [ngClass]="{iconClass: size == 'large', 'icon-size-5': size == 'medium', 'icon-size-4': size == 'small'}"
              [style.color]="iconColor"
              matPrefix>{{ icon }}
    </mat-icon>
    <mat-icon *ngIf="icon && iconLib !== 'mat'" [color]="color" [ngClass]="{iconClass: size == 'large', 'icon-size-5': size == 'medium', 'icon-size-4': size == 'small'}" [style.color]="iconColor"
              class="icon-size-4"
              matPrefix svgIcon="{{iconLib}}:{{icon}}"></mat-icon>
</ng-template>
