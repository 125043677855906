<div class="flex flex-col items-center w-full h-full">
    <div class="w-full">
        <div class="max-w-none mx-auto prose prose-sm">
            <img alt="Logo image" class="w-full sm:h-96 md:h-128 object-cover" src="../../../assets/images/img.webp">
        </div>

        <div class="flex justify-center p-6">
            <mdo-a-button color="primary" icon="arrow-long-right" iconLib="heroicons_mini" routerLink="/items"
                          type="raised">{{ 'home.button.orderNow' | transloco }}
            </mdo-a-button>
        </div>
    </div>
</div>
