import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {CurrencyConverterPipe} from "@moodeon-commons/pipe/currency-converter.pipe";
import {DialogContainerComponent} from "@moodeon-commons/component/dialog/dialog-container/dialog-container.component";
import {DialogContentComponent} from "@moodeon-commons/component/dialog/dialog-content/dialog-content.component";
import {DialogFooterComponent} from "@moodeon-commons/component/dialog/dialog-footer/dialog-footer.component";
import {DialogHeaderComponent} from "@moodeon-commons/component/dialog/dialog-header/dialog-header.component";
import {SeparatorComponent} from "@moodeon-commons/component/separator/separator.component";
import {TranslocoModule} from "@ngneat/transloco";
import {MatDialogRef} from "@angular/material/dialog";
import {BranchFilterComponent} from "@app/layout/common/branch-filter/branch-filter.component";
import {LanguagesComponent} from "@app/layout/common/languages/languages.component";
import {CurrenciesComponent} from "@app/layout/common/currencies/currencies.component";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {MerchantSettingsResponse} from "@app/model/merchant-user";

@Component({
    selector: 'app-welcome-pop-up',
    standalone: true,
    imports: [CommonModule, ButtonComponent, CurrencyConverterPipe, DialogContainerComponent, DialogContentComponent,
        DialogFooterComponent, DialogHeaderComponent, SeparatorComponent, TranslocoModule, BranchFilterComponent, LanguagesComponent, CurrenciesComponent],
    templateUrl: './welcome-pop-up.component.html',
    styles: []
})
export class WelcomePopUpComponent extends BaseComponent {
    merchantSettings: MerchantSettingsResponse;

    constructor(public dialogRef: MatDialogRef<WelcomePopUpComponent>) {
        super();
    }

    ngOnInit(): void {
        this.merchantSettings = LocalStorageHandler.MERCHANT_SETTINGS.get();
    }
}
