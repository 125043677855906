import {Component, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {AButtonComponent} from "@moodeon-commons/component/button/a-button/a-button.component";
import {TranslocoModule} from "@ngneat/transloco";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule, AButtonComponent, TranslocoModule],
})
export class HomeComponent {
    /**
     * Constructor
     */
    constructor() {
    }
}
