import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, tap} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {Currency, ExchangeRate} from "@moodeon-commons/model/currency";
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";

@Injectable({
    providedIn: 'root'
})
export class CurrencyService extends BaseCrudService<any> {
    baseUrl: string = environment.apiBaseUrl;

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getCurrencies(): Observable<ApiResponse<Currency[]>> {
        return this._httpClient.get<ApiResponse<Currency[]>>(this.baseUrl + '/' + this.getContextPath())
            .pipe(tap((response) => {
                this._pagination.next(response.metadata);
                this._items.next(response.payload);
            }));
    }

    getContextPath(): string {
        return "currencies";
    }

    getExchangeRates(provider?: string) {
        let params = {};
        if (provider) {
            params = {params};
        }
        return this._httpClient.get<ApiResponse<ExchangeRate[]>>('http://localhost:8080/' + this.getContextPath() + '/exchange-rates', {params: params});
    }

    updateExchangeRates(requestBody: ExchangeRate[]): Observable<ApiResponse<ExchangeRate[]>> {
        return this._httpClient.put<ApiResponse<ExchangeRate[]>>(this.baseUrl + '/' + this.getContextPath() + '/exchange-rates', requestBody);
    }
}
