import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {catchError, Observable, throwError} from 'rxjs';
import {TokenProps} from "@app/core/auth/token-props";
import {CurrencyHandler} from "@moodeon-commons/handler/currency-handler";
import {environment} from "../../../environments/environment";
import {KeycloakService} from "keycloak-angular";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);
    const keycloakService = inject(KeycloakService);

    let newReq = req.clone();

    const token = keycloakService.getKeycloakInstance()?.token;
    if (token && !AuthUtils.isTokenExpired(token)) {
        newReq = newReq.clone({
            headers: newReq.headers.set('Authorization', 'Bearer ' + token)
        });
    }

    if (!TokenProps.tenant) {
        TokenProps.tenant = window.location.href.match(environment.urlRegex)[1];
    }

    const currentBranch = LocalStorageHandler.CURRENT_BRANCH.get();
    if (currentBranch?.id > 0) {
        newReq = newReq.clone({
            headers: newReq.headers.set('x-branch-id', currentBranch.id + '')
        });
    }

    const currency = CurrencyHandler.getCurrentCurrency()?.code;
    if (currency) {
        newReq = newReq.clone({
            headers: newReq.headers.set('x-currency', currency)
        });
    }

    if (TokenProps.tenant && !req.url.includes(environment.tenantIgnoreUrls[0])) {
        newReq = newReq.clone({
            headers: newReq.headers.set('x-tenant', TokenProps.tenant)
        });
    }

    // Response
    return next(newReq).pipe(
        catchError((error) => {
            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
                // Sign out
                authService.signOut();
                authService.signIn(window.location.href);
            }

            return throwError(error);
        }),
    );
};
