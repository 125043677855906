<button
    (click)="gotoCart()"
    mat-icon-button>
    <ng-container *ngIf="itemCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-primary-600 text-primary-50 text-xs font-medium">
                {{ itemCount }}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
</button>
