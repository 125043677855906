import {Component, Inject, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {FuseAlertComponent} from "../../../@fuse/components/alert";
import {IconComponent} from "@moodeon-commons/component/icon/icon.component";
import {NotificationListItemResponse} from "@app/model/notification";
import {notificationMetadata} from "@app/layout/common/notifications/notification-metadata";
import {RouterLink} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {NotificationService} from "@app/service/notification.service";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {CommonEvents} from "@moodeon-commons/util/common-events";

@Component({
    selector: 'mdo-notification-alert',
    standalone: true,
    imports: [CommonModule, FuseAlertComponent, IconComponent, RouterLink, MatIconModule, MatTooltipModule, MatButtonModule],
    templateUrl: './notification-alert.component.html',
    styles: []
})
export class NotificationAlertComponent implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: NotificationListItemResponse,
                private notificationService: NotificationService, public snackBarRef: MatSnackBarRef<any>) {
        console.log('Notification Alert Component', notification);
    }

    hasLink(notification: NotificationListItemResponse): boolean {
        if (notification == null) {
            return false;
        }

        return notificationMetadata[notification.type]?.urlTemplate?.length > 0;
    }

    getRouterLink(notification: NotificationListItemResponse): string {
        if (notification == null) {
            return null;
        }

        const metadata = notificationMetadata[notification.type];

        if (!metadata?.urlTemplate || metadata?.urlTemplate === '') {
            return null;
        }

        if (!metadata.paramKeys || metadata.paramKeys.length == 0) {
            return metadata?.urlTemplate;
        }

        let finalUrl = metadata?.urlTemplate;
        for (let paramKey of metadata.paramKeys) {
            finalUrl = finalUrl.replace('{' + paramKey + '}', notification.params?.[paramKey]);
        }

        return finalUrl;
    }

    onClicked(notification: NotificationListItemResponse) {
        this.dismiss();
        if (!notification.read) {
            this.markAsRead(notification, true);
        }
    }

    markAsRead(notification: NotificationListItemResponse, read: boolean) {
        notification.read = read;
        this.notificationService.toggleReadStatus(notification.id, read).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.ALL_NOTIFICATIONS_CHANGED, null);
            }, error => {
                notification.read = !read;
            });
    }

    dismiss(): void {
        this.snackBarRef.dismiss();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
