import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {environment} from "../../environments/environment";
import {CartRequest, CartResponse} from "@app/model/cart";
import {CartItemRequest, CartItemResponse, CartStage} from "@app/model/cart-item";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    baseUrl: string = environment.apiBaseUrl;

    constructor(private _httpClient: HttpClient) {
    }

    createCart(req: CartRequest): Observable<ApiResponse<any>> {
        return this._httpClient.post<ApiResponse<any>>(this.baseUrl + '/cart', req);
    }

    addItemToCart(cartItemRequest: any): Observable<ApiResponse<any>> {
        return this._httpClient.post<ApiResponse<any>>(this.baseUrl + '/cart/item', cartItemRequest);
    }

    clearCart(): Observable<ApiResponse<any>> {
        return this._httpClient.delete<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart'));
    }

    getCartCount(): Observable<ApiResponse<any>> {
        return this._httpClient.get<ApiResponse<any>>(this.baseUrl + '/cart/count');
    }

    getCartItemById(cartItemId: number): Observable<ApiResponse<CartItemResponse>> {
        return this._httpClient.get<ApiResponse<CartItemResponse>>(this.concatUrl(this.baseUrl, 'cart/item', cartItemId));
    }

    getCart(): Observable<ApiResponse<CartResponse>> {
        return this._httpClient.get<ApiResponse<CartResponse>>(this.baseUrl + '/cart');
    }

    populateCart(cartRequest: CartRequest): Observable<ApiResponse<CartResponse>> {
        return this._httpClient.post<ApiResponse<CartResponse>>(this.baseUrl + '/cart/populate', cartRequest);
    }

    populateCartItem(cartItemRequest: CartItemRequest): Observable<ApiResponse<CartItemResponse>> {
        return this._httpClient.post<ApiResponse<CartItemResponse>>(this.baseUrl + '/cart/item/populate', cartItemRequest);
    }

    deleteCartItem(cartItemId: number): Observable<ApiResponse<CartResponse>> {
        return this._httpClient.delete<ApiResponse<CartResponse>>(this.concatUrl(this.baseUrl, 'cart', 'item', cartItemId));
    }

    concatUrl(...paths: any[]): string {
        return paths.join('/');
    }

    updateCartItem(cartItemId: number, cartItemRequest: any) {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart/item', cartItemId), cartItemRequest);
    }

    patchCartData(stage: CartStage, cartRequest: CartRequest) {
        return this._httpClient.patch<ApiResponse<CartResponse>>(this.concatUrl(this.baseUrl, 'cart', stage), cartRequest);
    }

    updateCustomerDetails(req: any) {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', 'customer-details'), req);
    }

    convertToOrder() {
        return this._httpClient.post<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', 'convert-to-order'), null);
    }

    placeOrder(req: CartRequest) {
        return this._httpClient.post<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', 'place-order'), req);
    }
}
