import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {
    AvailableItemVariantResponse,
    ItemAttributesRequest,
    ItemAttributesResponse,
    ItemAvailabilityRequest,
    ItemAvailabilityResponse,
    ItemGalleryElementResponse,
    ItemInventoryRequest,
    ItemInventoryResponse,
    ItemListItemResponse,
    ItemResponse,
    ItemSeoResponse
} from "@app/model/item";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";

@Injectable({
    providedIn: 'root'
})
export class ItemService extends BaseCrudService<ItemListItemResponse, ItemResponse, ItemResponse> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "items";
    }

    getItemAttributes(itemId: number): Observable<ApiResponse<ItemAttributesResponse>> {
        return this._httpClient.get<ApiResponse<ItemAttributesResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/attributes');
    }

    updateItemAttributes(itemId: number, requestBody: ItemAttributesRequest | FormData): Observable<ApiResponse<ItemAttributesResponse>> {
        return this._httpClient.put<ApiResponse<ItemAttributesResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/attributes', requestBody);
    }

    getItemAvailability(itemId: number): Observable<ApiResponse<ItemAvailabilityResponse>> {
        return this._httpClient.get<ApiResponse<ItemAvailabilityResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/availability');
    }

    updateItemAvailability(itemId: number, requestBody: ItemAvailabilityRequest | FormData): Observable<ApiResponse<ItemAvailabilityResponse>> {
        return this._httpClient.put<ApiResponse<ItemAvailabilityResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/availability', requestBody);
    }

    getItemInventory(itemId: number): Observable<ApiResponse<ItemInventoryResponse>> {
        return this._httpClient.get<ApiResponse<ItemInventoryResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/inventory');
    }

    updateItemInventory(itemId: number, requestBody: ItemInventoryRequest | FormData): Observable<ApiResponse<ItemInventoryResponse>> {
        return this._httpClient.put<ApiResponse<ItemInventoryResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/inventory', requestBody);
    }

    getItemGallery(itemId: number): Observable<ApiResponse<ItemGalleryElementResponse[]>> {
        return this._httpClient.get<ApiResponse<ItemGalleryElementResponse[]>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/gallery');
    }

    deleteItemGalleryItem(itemId: number, galleryItemId: number): Observable<ApiResponse<any>> {
        return this._httpClient.delete<ApiResponse<any>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/gallery/' + galleryItemId);
    }

    getItemSeo(itemId: number): Observable<ApiResponse<ItemSeoResponse>> {
        return this._httpClient.get<ApiResponse<ItemSeoResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/seo');
    }

    updateItemSeo(itemId: number, requestBody: FormData): Observable<ApiResponse<ItemSeoResponse>> {
        return this._httpClient.put<ApiResponse<ItemSeoResponse>>(this.baseUrl + '/' + this.getContextPath() + '/' + itemId + '/seo', requestBody);
    }

    getByItemVariantId(itemVariantId: number, withHeaders?: boolean): Observable<ApiResponse<ItemResponse>> {
        return this._httpClient.get<ApiResponse<ItemResponse>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'by-item-variant', itemVariantId), withHeaders ? {params: {withHeaders}} : null);
    }

    getAvailableVariants(itemId: number, withHeaders?: boolean): Observable<ApiResponse<AvailableItemVariantResponse[]>> {
        return this._httpClient.get<ApiResponse<AvailableItemVariantResponse[]>>(this.concatUrl(this.baseUrl, this.getContextPath(), itemId, 'available-variants'));
    }
}
