import {Component, Injector} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddButtonComponent} from "@moodeon-commons/component/button/add-button/add-button.component";
import {DataTableComponent} from "@moodeon-commons/component/data-table/data-table.component";
import {PageContainerComponent} from "@moodeon-commons/component/page-container/page-container.component";
import {PageHeaderComponent} from "@moodeon-commons/component/page-header/page-header.component";
import {SearchInputComponent} from "@moodeon-commons/component/input/search-input/search-input.component";
import {NotificationService} from "@app/service/notification.service";
import {
    BaseListViewPageWithAddViewEditDialog
} from "@moodeon-commons/component/base-list-view-page-with-add-view-edit-dialog";
import {TableHeader} from "@moodeon-commons/model/table-header";
import {Language} from "@moodeon-commons/model/language";
import {ComponentType} from "@app/model/role";
import {BreadcrumbItem} from "@moodeon-commons/model/breadcrumb-item";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {NotificationFilter, NotificationListItemResponse} from "@app/model/notification";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";
import {notificationMetadata} from "@app/layout/common/notifications/notification-metadata";
import {Subject, takeUntil} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {CommonEvents} from "@moodeon-commons/util/common-events";

@Component({
    selector: 'app-view-all-notifications',
    standalone: true,
    imports: [CommonModule, AddButtonComponent, DataTableComponent, PageContainerComponent, PageHeaderComponent,
        SearchInputComponent, MatButtonModule, MatIconModule, MatTooltipModule, RouterLink, TranslocoModule],
    templateUrl: './view-all-notifications.component.html',
    styles: []
})
export class ViewAllNotificationsComponent extends BaseListViewPageWithAddViewEditDialog<NotificationListItemResponse, NotificationListItemResponse, NotificationListItemResponse, NotificationFilter> {
    noMoreNotifications = false;
    destroyed = false;
    protected _unsubscribeMarkAsRead: Subject<any> = new Subject<any>();

    constructor(private notificationService: NotificationService, _injector: Injector) {
        super(notificationService, _injector);

        window.addEventListener(CommonEvents.NOTIFICATIONS_CHANGED, (event: CustomEvent<any>) => {
            this.loadData('reload');
        });
    }

    createBackEndDataFilter(): NotificationFilter {
        const filter = super.createBackEndDataFilter();
        filter.markAsDelivered = true;
        return filter;
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    hasLink(notification: NotificationListItemResponse): boolean {
        if (notification == null) {
            return false;
        }

        return notificationMetadata[notification.type]?.urlTemplate?.length > 0;
    }

    toggleRead(notification: NotificationListItemResponse): void {
        const currentStatus = notification.read;

        this.markAsRead(notification, !currentStatus);
    }

    markAsRead(notification: NotificationListItemResponse, read: boolean) {
        notification.read = read;
        this.notificationService.toggleReadStatus(notification.id, read).pipe(takeUntil(this._unsubscribeMarkAsRead))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.ALL_NOTIFICATIONS_CHANGED, null);
                if (this.destroyed) {
                    this._unsubscribeMarkAsRead.next(null);
                    this._unsubscribeMarkAsRead.complete();
                }
            }, error => {
                notification.read = !read;
                if (this.destroyed) {
                    this._unsubscribeMarkAsRead.next(null);
                    this._unsubscribeMarkAsRead.complete();
                }
            });
    }

    delete(notification: NotificationListItemResponse): void {
        this.notificationService.delete(notification.id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                CommonEvents.dispatchEvent(CommonEvents.ALL_NOTIFICATIONS_CHANGED, null);
                this.loadData('reload');
            });
    }

    getRouterLink(notification: NotificationListItemResponse): string[] {
        if (notification == null) {
            return null;
        }

        const metadata = notificationMetadata[notification.type];

        if (!metadata?.urlTemplate || metadata?.urlTemplate === '') {
            return null;
        }

        if (!metadata.paramKeys || metadata.paramKeys.length == 0) {
            return ['../', metadata?.urlTemplate];
        }

        let finalUrl = metadata?.urlTemplate;
        for (let paramKey of metadata.paramKeys) {
            finalUrl = finalUrl.replace('{' + paramKey + '}', notification.params?.[paramKey]);
        }

        return ['../', ...finalUrl.split('/')];
    }

    onClicked(notification: NotificationListItemResponse) {
        if (!notification.read) {
            this.markAsRead(notification, true);
        }
    }

    loadMore() {
        if (this.isLoading) {
            return;
        }

        if (!this.filter.pageNo) {
            this.filter.pageNo = 0;
        }

        this.filter.pageNo = this.filter.pageNo + 1;
        this.loadData();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyed = true;
    }

    protected getTableHeaders(): TableHeader[] {
        return [];
    }

    protected onLanguageChanged(newLang: Language) {
        this.loadData('reload');
    }

    protected getViewAddEditDialog(): any {
        return null;
    }

    protected getComponent(): ComponentType {
        return 'NOTIFICATION';
    }

    protected createBreadcrumbItems(): BreadcrumbItem[] {
        return [
            {icon: 'home', iconLib: 'heroicons_solid', routerLink: '/'},
            {title: translate('component.notification')}
        ];
    }

    protected onDataLoaded(response: ApiResponse<NotificationListItemResponse[]>, reference?: string) {
        if (!this.items) {
            this.items = [];
        }

        if (reference == 'reload') {
            this.items = [];
        }

        if (response.payload.length < 10) {
            this.noMoreNotifications = true;
        }

        this.items.push(...response.payload);
        if (!this.headersLoaded) {
            this.setupHeaders(response?.tableHeaders);
        }
        this.isLoading = false;
    }
}
