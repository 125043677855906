import {Route} from '@angular/router';
import {initialDataResolver} from 'app/app.resolvers';
import {HomeComponent} from "./modules/home/home.component";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {FavouriteItemsListComponent} from "@app/modules/item/favourite-item-list/favourite-items-list.component";
import {
    ViewAllNotificationsComponent
} from "@app/layout/common/notifications/view-all-notifications/view-all-notifications.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {
        path: '',
        pathMatch : 'full',
        redirectTo: 'home',
        providers: [{provide: TRANSLOCO_SCOPE, useValue: '', multi: true}],
    },
    {
        path: 'home',
        component: HomeComponent,
        resolve: {
            initialData: initialDataResolver
        },
        providers: [{provide: TRANSLOCO_SCOPE, useValue: 'home', multi: true}],
    },
    {
        path: 'notifications',
        component: ViewAllNotificationsComponent
    },
    {
        path: 'items',
        loadChildren: () => import('@app/modules/item/item.routes'),
        providers: [{provide: TRANSLOCO_SCOPE, useValue: 'item'}],
    },
    {
        path: 'cart',
        loadChildren: () => import('@app/modules/cart/cart.routes'),
        providers: [{provide: TRANSLOCO_SCOPE, useValue: 'cart'}],
    },
    {
        path: 'orders',
        loadChildren: () => import('@app/modules/order/order.routes'),
        providers: [{provide: TRANSLOCO_SCOPE, useValue: 'order'}],
    },
    {
        path: 'favourites',
        component: FavouriteItemsListComponent,
        providers: [{provide: TRANSLOCO_SCOPE, useValue: 'item'}],
    }
];
