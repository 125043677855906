export const notificationMetadata: { [key: string]: NotificationMetadata } = {
    'ORDER_CONFIRMED': {
        urlTemplate: 'orders/{orderNo}',
        paramKeys: ['orderNo']
    },
    'ORDER_STATUS_CHANGE': {
        urlTemplate: 'orders/{orderNo}',
        paramKeys: ['orderNo']
    }
};

export interface NotificationMetadata {
    urlTemplate: string;
    paramKeys?: string[];
}
