<div class="m-4">
    <h1 class="text-2xl sm:text-3xl md:text-4xl font-extrabold dark:text-white">{{ 'item.favouritesHeader' | transloco }}</h1>
    <span *ngIf="favouritesCount != null"
          class="font-semibold text-secondary">{{ favouritesCount || 0 }} {{ pluralize('Item', favouritesCount) }}</span>

    <div *ngIf="favouritesCount && favouritesCount == 0" class="mt-4 text-lg">
        <span>{{ 'item.favouritesEmptyMessage' | transloco }}</span>
    </div>

    <div class="mt-4">
        <div *ngIf="favourites"
             class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10 gap-6 w-full">
            <thumb-type-product-card *ngFor="let item of favourites" [item]="item"
                                     class="sm:col-span-1"></thumb-type-product-card>
        </div>
    </div>
</div>
