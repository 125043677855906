export const environment = {
    production: false,
    title: 'Dev Environment',
    apiBaseUrl: 'https://customer-api.moodeon.eu',
    imageBaseUrl: 'https://customer.moodeon.eu/',
    urlRegex: /https?:\/\/([^\/]+)\.moodeon\.eu/,
    tenantIgnoreUrls: ['admin-api.moodeon.fi'],
    keycloak: {
        authUrl: 'https://auth.moodeon.fi',
        clientId: 'customer-ui',
        adminClientId: 'admin-ui',
        adminRealm: 'moodeon-admin'
    }
};
