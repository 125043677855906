import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ItemService} from "@app/service/item.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {AvailableItemVariantResponse} from "@app/model/item";
import {DialogFooterComponent} from "@moodeon-commons/component/dialog/dialog-footer/dialog-footer.component";
import {DialogContainerComponent} from "@moodeon-commons/component/dialog/dialog-container/dialog-container.component";
import {TranslocoModule} from "@ngneat/transloco";
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {DialogHeaderComponent} from "@moodeon-commons/component/dialog/dialog-header/dialog-header.component";
import {DialogContentComponent} from "@moodeon-commons/component/dialog/dialog-content/dialog-content.component";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {SeparatorComponent} from "@moodeon-commons/component/separator/separator.component";
import {CurrencyConverterPipe} from "@moodeon-commons/pipe/currency-converter.pipe";

@Component({
    selector: 'app-select-variant-pop-up',
    standalone: true,
    imports: [CommonModule, DialogFooterComponent, DialogContainerComponent, DialogHeaderComponent, DialogContentComponent, TranslocoModule, ButtonComponent, SeparatorComponent, CurrencyConverterPipe],
    templateUrl: './select-variant-pop-up.component.html',
    providers: [CurrencyConverterPipe, CurrencyPipe],
    styles: []
})
export class SelectVariantPopUpComponent extends BaseComponent implements OnInit {
    availableItems: AvailableItemVariantResponse[];
    protected readonly ImageUtils = ImageUtils;

    constructor(public dialogRef: MatDialogRef<SelectVariantPopUpComponent>, @Inject(MAT_DIALOG_DATA) protected dialogData: any, private itemService: ItemService) {
        super();
    }

    ngOnInit(): void {
        this.itemService.getAvailableVariants(this.dialogData.parentItemId, true).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.availableItems = response.payload;
            })
    }
}
