<button
    [matMenuTriggerFor]="userActions"
    mat-icon-button>
    <span class="relative">
        <img [src]="profilePic || userPlaceholder" class="profile-image">
        <span [ngClass]="{'mr-px mb-px': !showAvatar || !user.profilePic}"
              class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
        ></span>
    </span>
</button>

<mat-menu
    #userActions="matMenu"
    [xPosition]="'before'">
    <div *ngIf="authenticated" class="px-4 pt-2 pb-4">
        <span class="flex flex-col leading-none">
            <span class="font-medium">{{ user?.firstName }} {{ user?.lastName }}</span>
            <span class="mt-1.5 text-sm text-secondary">@{{ user.username }}</span>
        </span>
    </div>
    <ng-container *ngIf="authenticated">
        <mdo-separator margin="0"></mdo-separator>
        <button [routerLink]="['/profile']" mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            <span>{{ 'component.profile' | transloco }}</span>
        </button>
    </ng-container>
    <mdo-separator *ngIf="authenticated" margin="0"></mdo-separator>
    <button (click)="signOut()" *ngIf="authenticated" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{ 'other.signOut' | transloco }}</span>
    </button>
    <button (click)="signIn()" *ngIf="!authenticated" mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:arrow-left-on-rectangle'"></mat-icon>
        <span>{{ 'other.signIn' | transloco }}</span>
    </button>
</mat-menu>
