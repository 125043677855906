import {Currency} from "@moodeon-commons/model/currency";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

export class CurrencyHandler {
    public static DEFAULT_CURRENCY = 'USD';

    // static getBaseCurrency(): string {
    //     return LocalStorageHandler.CURRENT_USER.get()?.baseCurrency || CurrencyHandler.DEFAULT_CURRENCY;
    // }

    static getBaseCurrency(): string {
        return CurrencyHandler.DEFAULT_CURRENCY;
    }

    static getCurrentCurrency(): Currency {
        return LocalStorageHandler.CURRENT_CURRENCY.get();
    }

    static setCurrentCurrency(currency: Currency): void {
        LocalStorageHandler.CURRENT_CURRENCY.set(currency);
    }
}
