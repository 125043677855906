import {Injectable} from '@angular/core';
import {ActionType, ComponentType} from "@app/model/role";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {KeycloakService} from "keycloak-angular";
import {CurrentUser} from "@app/model/customer";

@Injectable({providedIn: 'root'})
export class AuthService {
    private _authenticated: boolean = false;

    constructor(private keycloakService: KeycloakService) {
    }

    getCurrentUser(): CurrentUser {
        return LocalStorageHandler.CURRENT_USER.get();
    }

    signOut(redirectUrl?: string) {
        sessionStorage.clear();
        LocalStorageHandler.CURRENT_USER.remove();
        this.keycloakService.logout(redirectUrl);
    }

    hasAnyPermission(components: ComponentType[]): boolean {
        const currentUser = this.getCurrentUser();
        if (currentUser == null || currentUser.grants == null || Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        for (let component of components) {
            if (currentUser.grants[component]) {
                return true;
            }
        }

        return false;
    }

    hasAnyActionPermission(component: ComponentType, actions: ActionType[]): boolean {
        const currentUser = this.getCurrentUser();
        if (actions == null || actions.length == 0 || currentUser == null || currentUser.grants == null ||
            Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        for (let action of actions) {
            if (currentUser.grants[component]?.includes(action)) {
                return true;
            }
        }

        return false;
    }

    hasExactPermission(component: ComponentType, action?: ActionType): boolean {
        const currentUser = this.getCurrentUser();
        if (currentUser == null || currentUser.grants == null || Object.keys(currentUser.grants).length == 0) {
            return false;
        }

        if (action) {
            return currentUser.grants[component]?.includes(action);
        }

        return currentUser.grants[component] != null;
    }

    signIn(redirectUri: string) {
        this.keycloakService.login({
            redirectUri: redirectUri,
            locale: LocalStorageHandler.CURRENT_LANGUAGE.get()?.code
        });
    }
}
