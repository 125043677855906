import {SortDirection} from "@angular/material/sort";

export type CustomizableFields = { [key: string]: any };

export class ResponseMetadata {
    currentPage: number;
    totalPages: number;
    itemsPerPage: number;
    totalItems: number;
}

export class BaseFilter {
    pageSize?: number;
    pageNo?: number;
    searchQuery?: string;
    sortBy?: string[];
    sortDirection?: SortDirection;
    withHeaders?: boolean;
    customizableFields?: CustomizableFields;
}

export interface CommonError {
    code?: number;
    message?: string;
}

export interface FieldError {
    field?: string;
    code?: number;
    value?: any;
    message?: string;
}

export interface ErrorResponse {
    commonErrors?: CommonError[];
    fieldErrors?: FieldError[];
}
