<ng-container *ngIf="!child">
    <div
        #trigger="matMenuTrigger"
        (onMenuClose)="triggerChangeDetection()"
        (onMenuOpen)="triggerChangeDetection()"
        [matMenuTriggerFor]="matMenu"
        [ngClass]="{'fuse-horizontal-navigation-menu-active': trigger.menuOpen,
                 'fuse-horizontal-navigation-menu-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
    </div>
</ng-container>

<mat-menu
    #matMenu="matMenu"
    [overlapTrigger]="false"
    class="fuse-horizontal-navigation-menu-panel">

    <ng-container *ngFor="let item of item.children; trackBy: trackByFn">

        <!-- Skip the hidden items -->
        <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">

            <!-- Basic -->
            <ng-container *ngIf="item.type === 'basic'">
                <div
                    [disabled]="item.disabled"
                    class="fuse-horizontal-navigation-menu-item"
                    mat-menu-item>
                    <fuse-horizontal-navigation-basic-item
                        [item]="item"
                        [name]="name"></fuse-horizontal-navigation-basic-item>
                </div>
            </ng-container>

            <!-- Branch: aside, collapsable, group -->
            <ng-container *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'">
                <div
                    [disabled]="item.disabled"
                    [matMenuTriggerFor]="branch.matMenu"
                    class="fuse-horizontal-navigation-menu-item"
                    mat-menu-item>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
                    <fuse-horizontal-navigation-branch-item
                        #branch
                        [child]="true"
                        [item]="item"
                        [name]="name"></fuse-horizontal-navigation-branch-item>
                </div>
            </ng-container>

            <!-- Divider -->
            <ng-container *ngIf="item.type === 'divider'">
                <div
                    class="fuse-horizontal-navigation-menu-item"
                    mat-menu-item>
                    <fuse-horizontal-navigation-divider-item
                        [item]="item"
                        [name]="name"></fuse-horizontal-navigation-divider-item>
                </div>
            </ng-container>

        </ng-container>

    </ng-container>

</mat-menu>

<!-- Item template -->
<ng-template
    #itemTemplate
    let-item>

    <div
        [class.fuse-horizontal-navigation-item-has-subtitle]="!!item.subtitle"
        [ngClass]="item.classes?.wrapper"
        class="fuse-horizontal-navigation-item-wrapper">

        <div
            [matTooltip]="item.tooltip || ''"
            [ngClass]="{'fuse-horizontal-navigation-item-disabled': item.disabled,
                         'fuse-horizontal-navigation-item-active-forced': item.active}"
            class="fuse-horizontal-navigation-item">

            <!-- Icon -->
            <ng-container *ngIf="item.icon">
                <mat-icon
                    [ngClass]="item.classes?.icon"
                    [svgIcon]="item.icon"
                    class="fuse-horizontal-navigation-item-icon"></mat-icon>
            </ng-container>

            <!-- Title & Subtitle -->
            <div *transloco="let t; read:'navigation'" class="fuse-horizontal-navigation-item-title-wrapper">
                <div class="fuse-horizontal-navigation-item-title">
                    <span [ngClass]="item.classes?.title">
                        {{ item.title || t(item.titleLabel ? item.titleLabel : item.id) }}
                    </span>
                </div>
                <ng-container *ngIf="item.subtitle">
                    <div class="fuse-horizontal-navigation-item-subtitle text-hint">
                        <span [ngClass]="item.classes?.subtitle">
                            {{ item.subtitle || t(item.subtitleLabel) }}
                        </span>
                    </div>
                </ng-container>
            </div>

            <!-- Badge -->
            <ng-container *ngIf="item.badge">
                <div class="fuse-horizontal-navigation-item-badge">
                    <div
                        [ngClass]="item.badge.classes"
                        class="fuse-horizontal-navigation-item-badge-content">
                        {{ item.badge.title }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</ng-template>
