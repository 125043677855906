import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {environment} from "../../environments/environment";
import {MerchantSettingsResponse} from "@app/model/merchant-user";

@Injectable({
    providedIn: 'root'
})
export class MerchantService {
    baseUrl: string = environment.apiBaseUrl;

    constructor(private _httpClient: HttpClient) {
    }

    getContextPath(): string {
        return "branches";
    }

    getMerchantSettings(): Observable<ApiResponse<MerchantSettingsResponse>> {
        return this._httpClient.get<ApiResponse<MerchantSettingsResponse>>(this.baseUrl + '/merchant/settings');
    }
}
