import {BaseFilter} from "@moodeon-commons/model/common";
import {BaseModel} from "@moodeon-commons/model/base-model";
import {Lookup} from "@app/model/lookup";
import {ContactPhone} from "@app/model/merchant";
import {ActionType} from "@app/model/role";

export class CustomerListItemResponse extends BaseModel {
    profilePic: string;
    firstName: string;
    lastName: string;
    email: string;
    contact: string;
    status: Lookup;
}

export class CustomerRequest extends BaseModel {
    profilePic: string;
    firstName: string;
    lastName: string;
    email: string;
    contact: ContactPhone;
    username: string;
    status: string;
}

export class CustomerResponse extends BaseModel {
    profilePic: string;
    firstName: string;
    lastName: string;
    email: string;
    contact: ContactPhone;
    username: string;
    status: string;
}

export class CustomerFilter extends BaseFilter {

}

export class CurrentUser extends BaseModel {
    profilePic: string;
    firstName: string;
    middleNames: string;
    lastName: string;
    email: string;
    contact: ContactPhone;
    username: string;
    grants: { [key: string]: ActionType[] };
    defaultLanguage: string;
    status: string;
}
