import {Pipe, PipeTransform} from "@angular/core";
import Pluralize from "pluralize";

@Pipe({
    name: 'pluralize',
    pure: false,
    standalone: true,
})
export class PluralizePipe implements PipeTransform {
    transform(value: string): string {
        return Pluralize(value);
    }
}
