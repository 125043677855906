<div class="flex flex-col w-full py-8 px-6 md:px-8 bg-card dark:bg-transparent">
    <div class="flex flex-row justify-between">
        <div class="fuse-mat-no-subscript mr-10">
            <mdo-breadcrumb [breadcrumbItems]="breadcrumbItems"></mdo-breadcrumb>
            <h4 class="text-4xl font-extrabold tracking-tight">{{ title || (pluralize ? ('component.' + component | transloco | pluralize) : ('component.' + component | transloco)) }}</h4>
        </div>
        <div class="flex flex-row items-center gap-4">
            <ng-content></ng-content>
        </div>
    </div>
</div>
