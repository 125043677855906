<div class="justify-end gap-4 border-t flex flex-1 pb-2 px-8" mat-dialog-actions>
    <ng-content></ng-content>
    <div class="mt-11 mb-10 border-t border-gray-600"></div>
    <div class="flex items-center justify-end">
        <mdo-button (buttonClick)="dismissButtonClicked.emit($event)" *ngIf="showDismissButton && !readOnly"
                    type="stroked">{{ dismissButtonLabel | transloco }}
        </mdo-button>
        <mdo-button (buttonClick)="submitButtonCLicked.emit($event)" *ngIf="showSubmitButton && !readOnly"
                    [className]="'ml-4'" color="primary"
                    type="flat">{{ submitButtonLabel | transloco }}
        </mdo-button>
        <mdo-button (buttonClick)="dismissButtonClicked.emit($event)" *ngIf="readOnly" [className]="'ml-4'"
                    color="primary"
                    type="flat">{{ 'action.ok' | transloco }}
        </mdo-button>
    </div>
</div>
