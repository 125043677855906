import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from "@moodeon-commons/component/icon/icon.component";
import {BaseButtonComponent} from "@moodeon-commons/component/base-button";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";

@Component({
    selector: 'mdo-button',
    standalone: true,
    imports: [CommonModule, IconComponent, MatButtonModule, MatInputModule, MatIconModule],
    templateUrl: './button.component.html',
    styles: []
})
export class ButtonComponent extends BaseButtonComponent {

}
