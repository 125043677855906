import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {AfterViewInit, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseModel} from "@moodeon-commons/model/base-model";
import {BaseFilter, ResponseMetadata} from "@moodeon-commons/model/common";
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {MenuItem} from "@moodeon-commons/model/table/menu-item";
import {BreadcrumbItem} from "@moodeon-commons/model/breadcrumb-item";
import {FilterItem} from "@moodeon-commons/model/filter-item";
import {DialogService} from "@moodeon-commons/service/dialog-service";
import {AlertService} from "@moodeon-commons/service/alert.service";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {ObjectUtils} from "@moodeon-commons/util/object-utils";
import {TableRowAction} from "@moodeon-commons/model/table/table-row-action";
import {TableDataRow} from "@moodeon-commons/model/table/table-data-row";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {TableHeader} from "@moodeon-commons/model/table-header";
import pluralize from "pluralize";
import {translate} from "@ngneat/transloco";
import {AuthService} from "@app/core/auth/auth.service";
import {camelCase} from "lodash-es";
import {ComponentType} from "@app/model/role";
import {Language} from "@moodeon-commons/model/language";
import {TableHeaderResponse} from "@app/model/header";
import {DataType} from "@moodeon-commons/model/form";

@Component({
    template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class BaseListViewPage<ListItem extends BaseModel, Req extends BaseModel = ListItem, Res extends BaseModel = Req, Filter extends BaseFilter = BaseFilter> implements OnInit, AfterViewInit, OnDestroy {
    tableHeaders: TableHeader[];
    items: ListItem[];

    actions: MenuItem[];
    breadcrumbItems: BreadcrumbItem[];

    pagination: ResponseMetadata;
    filter: Filter = new BaseFilter() as Filter;
    filterItems: FilterItem[] = [];
    component: string;
    module: string;
    headersLoaded = false;

    inlineEditFields: { [key: string]: any };
    isLoading: boolean = false;
    hasCreatePermission: boolean;
    protected _dialogService: DialogService;
    protected _alertService: AlertService;
    protected _route: ActivatedRoute;
    protected _router: Router;
    protected _unsubscribeAll: Subject<any> = new Subject<any>();
    private inlineEditValues: any[];
    private _changeDetectorRef: ChangeDetectorRef;
    protected authService: AuthService;

    protected constructor(private _crudService: BaseCrudService<ListItem, Req, Res>, _injector: Injector) {
        this._dialogService = _injector.get(DialogService);
        this._alertService = _injector.get(AlertService);
        this._route = _injector.get(ActivatedRoute);
        this._router = _injector.get(Router);
        this._changeDetectorRef = _injector.get(ChangeDetectorRef);
        this.authService = _injector.get(AuthService);
        window.addEventListener(CommonEvents.LANGUAGE_CHANGED, (event: CustomEvent<Language>) => {
            this.breadcrumbItems = this.createBreadcrumbItems();
            this.onLanguageChanged(event?.detail);
        });
        this.tableHeaders = this.getTableHeaders();
        this.init();
        this.initPermissions();
    }

    getIdFromParams() {
        const routeParams = this._route.snapshot.paramMap;
        return Number(routeParams.get('id'));
    }

    public init(): void {
        this.component = camelCase(this.getComponent());
        this.module = this.getModule();
        this.actions = this.getGrantedActions();
        this.breadcrumbItems = this.createBreadcrumbItems();
        this.initFilterItems();
        this.readAndSetParams();
    }

    ngOnInit(): void {
        this._crudService.pagination$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((pagination: ResponseMetadata) => {
                this.pagination = pagination;
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    ngAfterViewInit(): void {

    }

    public readAndSetParams(): void {
        this._route.queryParams
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((params: Params) => {
                this.onQueryParamsLoaded(params);
            });
    }

    onQueryParamsLoaded(params: Params): void {
        const newFilters = {} as Filter;
        Object.keys(params).forEach(paramKey => {
            const filterItemSelected = this.filterItems.find(filterItem => filterItem.field === paramKey);

            if (filterItemSelected) {
                if (filterItemSelected.valueFieldType === 'BOOLEAN') {
                    newFilters[paramKey] = JSON.parse(params[paramKey]);
                } else if (filterItemSelected.valueFieldType === 'NUMBER_DECIMAL' || filterItemSelected.valueFieldType === 'NUMBER_INTEGER') {
                    newFilters[paramKey] = Number(params[paramKey]);
                } else {
                    newFilters[paramKey] = params[paramKey];
                }
            } else if (paramKey === 'sortBy') {
                newFilters[paramKey] = [params[paramKey]];
            } else {
                newFilters[paramKey] = params[paramKey];
            }
        });

        this.filter = {...this.filter, ...newFilters};

        setTimeout(() => {
            this.loadData();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getModule(): string {
        return 'admin';
    }

    public initFilterItems(): void {
        this.filterItems = [];
    }

    public loadData(reference?: string): void {
        this.isLoading = true;
        this.createLoadDataRequest()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.onDataLoaded(response, reference);
                },
                error: (error) => {
                    this.isLoading = false;
                    this.handleError(error);
                }
            });
    }

    createBackEndDataFilter(): Filter {
        if (this.headersLoaded) {
            return this.filter;
        }

        return {...this.filter, withHeaders: true};
    }

    public onFilterChanged(filter: Filter, reference?: string): void {
        this.filter = filter;
        const filterValidated = ObjectUtils.cloneAndRemoveNulls(this.filter);
        if (filterValidated.sortBy) {
            filterValidated.sortBy = filterValidated.sortBy[0];
        }

        this.loadData(reference);
        this.updateRouterParams(filterValidated);
    }

    onInlineValueChange(values: any[]): void {
        this.inlineEditValues = values;
    }

    setOptionsToInlineEditField(key: string, options: any[]): void {
        this.inlineEditFields[key].options = options;
    }

    getMappedInlineValues(): any[] {
        return this.mapInlineValuesToRequestObjectList(this.inlineEditValues);
    }

    mapInlineValuesToRequestObjectList(values: any[]): any[] {
        const keys = Object.keys(this.inlineEditFields);
        const mappedValues = {};
        keys.forEach(key => {
            if (this.inlineEditFields[key].mappedValueField?.trim().length > 0 && this.inlineEditFields[key].mappedValueField?.trim() !== key) {
                mappedValues[key] = this.inlineEditFields[key].mappedValueField?.trim();
            }
        });

        const mappedValKeys = Object.keys(mappedValues);

        values.forEach(value => {
            mappedValKeys.forEach(valKey => {
                value[mappedValues[valKey]] = value[valKey];
                delete value[valKey];
            });
        });

        return values;
    }

    public onActionPicked(rowAction: TableRowAction): void {
        if (rowAction.action === 'view') {
            this.executeViewAction(rowAction.data);
        } else if (rowAction.action === 'edit') {
            this.executeEditAction(rowAction.data);
        } else if (rowAction.action === 'delete') {
            this.showDeleteDialog(rowAction.data);
        }
    }

    public onSearchTextChange(searchQuery: string): void {
        if (searchQuery === this.filter.searchQuery) {
            return;
        }

        this.filter.searchQuery = searchQuery;
        this.filter.pageNo = 0;
        this.onFilterChanged(this.filter);
    }

    public updateRouterParams(params: any): void {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: params
        });
    }

    public showDeleteDialog(rowData: TableDataRow): void {
        const dialogRef = this._dialogService.openDeleteDialog(this.component);

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'confirmed') {
                this._crudService?.delete(rowData.id).subscribe({
                    next: response => {
                        this._alertService.showDeleteSuccessAlert(this.component);
                        this.onDataDeleted(rowData);
                        this.loadData();
                    }, error: error => {
                        this._alertService.onError(error, this.component);
                    }
                });
            }
        });
    }

    public onAddButtonClicked(): void {
        this.executeAddNewAction();
    }

    protected onLanguageChanged(newLang: Language) {

    }

    protected createBreadcrumbItems(): BreadcrumbItem[] {
        return [
            {icon: 'home', iconLib: 'heroicons_solid', routerLink: '/'},
            {title: pluralize(translate('component.' + camelCase(this.getComponent())))}
        ];
    }

    protected createLoadDataRequest() {
        return this._crudService?.getList(this.createBackEndDataFilter());
    }

    protected onDataLoaded(response: ApiResponse<ListItem[]>, reference?: string) {
        this.items = response.payload;
        if (!this.headersLoaded) {
            this.setupHeaders(response?.tableHeaders);
        }
        this.isLoading = false;
    }

    protected setupHeaders(tableHeaders: TableHeaderResponse[]) {
        if (tableHeaders == null || tableHeaders.length == 0) {
            return;
        }

        if (this.tableHeaders == null) {
            this.tableHeaders = [];
        }

        for (let tableHeader of tableHeaders) {
            this.tableHeaders.push({
                field: 'customizableFields.' + tableHeader.field,
                title: tableHeader.name,
                sequenceOrder: Number(this.getProperty('SEQUENCE_ORDER', tableHeader)),
                dataType: tableHeader.type,
                columnWeight: Number(this.getProperty('TABLE_COLUMN_WEIGHT', tableHeader) || 1),
                // nonSortable: !Boolean(this.getProperty('SORTABLE', tableHeader))
                nonSortable: true
            });
        }

        this.tableHeaders = this.tableHeaders.sort((a, b) => a.sequenceOrder - b.sequenceOrder);

        if (!this.filterItems) {
            this.filterItems = [];
        }

        tableHeaders
            .filter(tableHeader => tableHeader.properties?.FILTERABLE == true)
            .forEach(tableHeader => this.filterItems.push(this.createFilter(tableHeader)));

        this.headersLoaded = true;
    }

    private createFilter(tableHeader: TableHeaderResponse) {
        if (tableHeader.type == 'BOOLEAN') {
            return {
                label: camelCase(this.getComponent()) + '.customizableFields.' + tableHeader.field,
                field: 'customizableFields.' + tableHeader.field,
                title: tableHeader.name,
                valueFieldType: 'BOOLEAN',
                valueField: 'code',
                displayField: 'name',
                dataType: 'SELECTION_SINGLE',
                options: [{name: 'Yes', code: true}, {name: 'No', code: false}]
            } as FilterItem;
        } else {
            return {
                label: camelCase(this.getComponent()) + '.customizableFields.' + tableHeader.field,
                field: 'customizableFields.' + tableHeader.field,
                title: tableHeader.name,
                valueFieldType: this.determineValueFieldType(tableHeader),
                valueField: this.determineValueField(tableHeader),
                displayField: 'name',
                dataType: tableHeader.type == 'SELECTION_MULTI' ? 'SELECTION_SINGLE' : tableHeader.type,
                options: tableHeader.properties?.OPTIONS
            } as FilterItem;
        }
    }

    determineValueField(tableHeader: TableHeaderResponse) {
        if (tableHeader.type == 'STATUS') {
            return 'code';
        }

        if (!tableHeader.properties?.OPTION_CODE_AVAILABLE) {
            return 'name';
        }

        return 'code';
    }

    determineValueFieldType(tableHeader: TableHeaderResponse): DataType {
        if (tableHeader.type == 'STATUS' || tableHeader.properties?.OPTION_CODE_AVAILABLE == true || !tableHeader.properties?.OPTION_DATA_TYPE) {
            return 'TEXT';
        }

        return tableHeader.properties?.OPTION_DATA_TYPE as DataType;
    }

    protected getProperty(propertyKey: string, tableHeader: TableHeaderResponse) {
        if (tableHeader == null || tableHeader.properties == null || propertyKey == null) {
            return null;
        }

        return tableHeader.properties[propertyKey];
    }

    protected abstract getTableHeaders(): TableHeader[];

    protected onDataDeleted(deletedData: any): void {
    }

    protected abstract executeViewAction(data: any): void;

    protected abstract executeEditAction(data: any): void;

    protected abstract executeAddNewAction(): void;

    protected createActions(): MenuItem[] {
        return [
            MenuItem.edit(),
            MenuItem.delete()
        ];
    }

    protected abstract getComponent(): ComponentType;

    private handleError(error: HttpErrorResponse) {
        console.log(error);
        this._alertService.showErrorAlert(error.message);
    }

    private getGrantedActions(): MenuItem[] {
        const definedActions = this.createActions();

        if (definedActions == null || definedActions.length == 0) {
            return null;
        }

        const actions = [];

        for (let definedAction of definedActions) {
            const hasPermission = this.authService.hasExactPermission(this.getComponent(), definedAction.grantAction)
            if (definedAction.action === 'edit') {
                if (!hasPermission) {
                    const hasViewAction = definedActions.filter(value => value.action === 'view');
                    const hasViewPermission = this.authService.hasExactPermission(this.getComponent(), 'VIEW_DETAIL');
                    if (hasViewAction.length == 0 && hasViewPermission) {
                        actions.push(MenuItem.view());
                    }
                } else {
                    actions.push(definedAction);
                }
            } else if (hasPermission) {
                actions.push(definedAction);
            }
        }

        return actions;
    }

    private initPermissions() {
        // todo

        this.hasCreatePermission = this.authService.hasExactPermission(this.getComponent(), 'CREATE');
    }
}
