import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config';
import {fromPairs} from 'lodash-es';
import {map, Observable, ReplaySubject, switchMap} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MediaWatcherService {
    private _onMediaChange: ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }> = new ReplaySubject<{
        matchingAliases: string[];
        matchingQueries: any
    }>(1);

    private _onScreenSizeChange: ReplaySubject<ScreenSize> = new ReplaySubject<ScreenSize>(1);

    /**
     * Constructor
     */
    constructor(
        private _breakpointObserver: BreakpointObserver,
        private _fuseConfigService: FuseConfigService,
    ) {
        this._fuseConfigService.config$.pipe(
            map(config => fromPairs(Object.entries(config.screens).map(([alias, screen]) => ([alias, `(min-width: ${screen})`])))),
            switchMap(screens => this._breakpointObserver.observe(Object.values(screens)).pipe(
                map((state) => {
                    // Prepare the observable values and set their defaults
                    const matchingAliases: string[] = [];
                    const matchingQueries: any = {};

                    // Get the matching breakpoints and use them to fill the subject
                    const matchingBreakpoints = Object.entries(state.breakpoints).filter(([query, matches]) => matches) ?? [];
                    for (const [query] of matchingBreakpoints) {
                        // Find the alias of the matching query
                        const matchingAlias = Object.entries(screens).find(([alias, q]) => q === query)[0];

                        // Add the matching query to the observable values
                        if (matchingAlias) {
                            matchingAliases.push(matchingAlias);
                            matchingQueries[matchingAlias] = query;
                        }
                    }

                    // Execute the observable
                    this._onMediaChange.next({
                        matchingAliases,
                        matchingQueries,
                    });

                    this._onScreenSizeChange.next(this.determineScreenSize(matchingAliases));
                }),
            )),
        ).subscribe();
    }

    get onMediaChange$(): Observable<{ matchingAliases: string[]; matchingQueries: any }> {
        return this._onMediaChange.asObservable();
    }

    get onScreenSizeChange$(): Observable<ScreenSize> {
        return this._onScreenSizeChange.asObservable();
    }

    onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
        return this._breakpointObserver.observe(query);
    }

    private determineScreenSize(matchingAliases: string[]): ScreenSize {
        if (!matchingAliases || matchingAliases.length === 0) {
            return {size: '', factor: 0};
        }

        if (matchingAliases.includes('xl')) {
            return {size: 'xl', factor: 4};
        }

        if (matchingAliases.includes('lg')) {
            return {size: 'lg', factor: 3};
        }

        if (matchingAliases.includes('md')) {
            return {size: 'md', factor: 2};
        }

        if (matchingAliases.includes('sm')) {
            return {size: 'sm', factor: 1};
        }

        return {size: '', factor: 0};
    }
}

export interface ScreenSize {
    size: string;
    factor: number;
}
