<a (click)="buttonClick.emit($event);" *ngIf="type === 'raised'" [class]="buttonClass" [color]="color"
   [disabled]="disabled"
   [class.rounded-md]="!rounded"
   [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" [routerLink]="routerLink" mat-raised-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<a (click)="buttonClick.emit($event);" *ngIf="type === 'stroked'" [class]="buttonClass" [color]="color"
   [disabled]="disabled"
   [class.rounded-md]="!rounded"
   [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" [routerLink]="routerLink" mat-stroked-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<a (click)="buttonClick.emit($event)" *ngIf="type === 'flat'" [class]="buttonClass" [color]="color"
   [disabled]="disabled"
   [class.rounded-md]="!rounded"
   [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" [routerLink]="routerLink" mat-flat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<a (click)="buttonClick.emit($event)" *ngIf="type === 'icon'" [class]="buttonClass" [color]="color"
   [disabled]="disabled"
   [class.rounded-md]="!rounded"
   [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" [routerLink]="routerLink" mat-icon-button>
    <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
</a>

<a (click)="buttonClick.emit($event)" *ngIf="type === '' || type == null" [class]="buttonClass" [color]="color"
   [disabled]="disabled"
   [class.rounded-md]="!rounded"
   [ngClass]="{'min-w-8 min-h-8 p-4 text-md h-8': size == 'medium', 'min-w-8 min-h-4 p-2 text-sm h-6': size == 'small'}" [routerLink]="routerLink" mat-button>
    <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>

<ng-template #buttonContent class="flex items-center">
    <div class="flex items-center">
        <ng-container [ngTemplateOutlet]="buttonIconContent"></ng-container>
        <span [ngClass]="{'mt-0.5': size == 'medium'}">
            <ng-content></ng-content>
        </span>
    </div>
</ng-template>

<ng-template #buttonIconContent>
    <mdo-icon *ngIf="icon" [class.mr-1]="type != 'icon' && buttonContent"
              [className]="size == 'medium' ? 'icon-size-5' : size == 'small' ? 'icon-size-4' : iconClass"
              [ngClass]="{iconClass: size == 'large', 'icon-size-5': size == 'medium', 'icon-size-4': size == 'small'}"
              [fillColor]="iconColor"
              [iconLib]="iconLib" [icon]="icon" [style.fill]="iconColor"
              matPrefix></mdo-icon>
</ng-template>
