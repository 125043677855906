import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'mdo-dialog-container',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './dialog-container.component.html',
    styles: []
})
export class DialogContainerComponent {
    @Input()
    inputClass: string = 'flex flex-col  max-h-screen-90 -m-6';

    @Input()
    widthClasses = 'max-w-540 sm:min-w-120 md:min-w-200 lg:min-w-280';
}
