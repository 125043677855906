import {NgClass, NgIf} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Router, RouterLink} from '@angular/router';
import {Subject, takeUntil} from 'rxjs';
import {CurrentUser} from "@app/model/customer";
import {AuthService} from "@app/core/auth/auth.service";
import {KeycloakService} from "keycloak-angular";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {CustomerService} from "@app/service/customer.service";
import {TranslocoModule} from "@ngneat/transloco";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {SeparatorComponent} from "@moodeon-commons/component/separator/separator.component";

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, RouterLink, TranslocoModule, SeparatorComponent],
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;
    user: CurrentUser = new CurrentUser();
    userPlaceholder = '../../../assets/images/placeholders/user_placeholder.png';
    profilePic = this.userPlaceholder;
    authenticated = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef,
        protected _router: Router, private readonly _keycloak: KeycloakService,
        private customerService: CustomerService) {
    }

    ngOnInit(): void {
        window.addEventListener(CommonEvents.PROFILE_DETAILS_UPDATED, (event: CustomEvent<CurrentUser>) => {
            this.setup(event.detail);
        });

        this._keycloak.isLoggedIn().then(loggedIn => {
            if (loggedIn) {
                this.customerService.getCurrentUser().pipe(takeUntil(this._unsubscribeAll)).subscribe(currentUser => {
                    this.authenticated = currentUser && currentUser.id > 0;
                    this.setup(currentUser);
                });
            }
        });
    }

    signOut(): void {
        this.authService.signOut(window.location.origin);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    signIn() {
        this.authService.signIn(window.location.href);
    }

    private setup(user: CurrentUser) {
        this.user = user;
        this.profilePic = ImageUtils.generateThumbnailPath(user.profilePic, true);
        this._changeDetectorRef.detectChanges();
    }
}
