import {NgFor, NgIf, NgTemplateOutlet, UpperCasePipe} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {Subject, takeUntil} from 'rxjs';
import {Currency} from "@moodeon-commons/model/currency";
import {CurrencyHandler} from "@moodeon-commons/handler/currency-handler";
import {CurrencyService} from "@app/service/currency.service";
import {MatRippleModule} from "@angular/material/core";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {MerchantSettingsResponse} from "@app/model/merchant-user";

@Component({
    selector: 'currencies',
    templateUrl: './currencies.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'currencies',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor, UpperCasePipe, NgIf, MatRippleModule],
})
export class CurrenciesComponent implements OnInit, OnDestroy {
    availableCurrencies: Currency[];
    activeCurrency: Currency;
    imgUrl: string
    enabled = false;

    @Input()
    defaultMode = true;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private currencyService: CurrencyService, private cdr: ChangeDetectorRef) {
        window.addEventListener(CommonEvents.CURRENCY_CHANGED, (event: CustomEvent<Currency>) => {
            this.activeCurrency = event.detail;
            this.resetFlagUrl(this.activeCurrency?.flag);
        });

        window.addEventListener(CommonEvents.MERCHANT_SETTINGS_REFRESHED, (event: CustomEvent<MerchantSettingsResponse>) => {
            if (this.defaultMode) {
                this.enabled = event.detail.enableMultiCurrencies;
                if (!this.enabled) {
                    this.cdr.detectChanges();
                    return;
                }

                this.setupUI();
            }
        });
    }

    ngOnInit(): void {
        if (this.defaultMode) {
            this.enabled = LocalStorageHandler.MERCHANT_SETTINGS.get()?.enableMultiCurrencies;
            if (!this.enabled) {
                this.cdr.detectChanges();
                return;
            }
        }

        this.setupUI();
    }

    setupUI() {
        this.activeCurrency = CurrencyHandler.getCurrentCurrency();
        this.currencyService.getCurrencies()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.availableCurrencies = response.payload;
                if (this.activeCurrency == null) {
                    this.activeCurrency = this.availableCurrencies[0];
                    CurrencyHandler.setCurrentCurrency(this.activeCurrency);
                    this.resetFlagUrl(this.activeCurrency?.flag);
                }
            });

        if (this.activeCurrency != null) {
            this.resetFlagUrl(this.activeCurrency?.flag);
        }
    }

    resetFlagUrl(flag: string) {
        this.imgUrl = 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/' + flag + '.svg';
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    setActiveCurrency(currency: Currency): void {
        if (this.activeCurrency?.code == currency.code) {
            return;
        }

        this.activeCurrency = currency;
        CurrencyHandler.setCurrentCurrency(currency);
        this.resetFlagUrl(this.activeCurrency?.flag);
        CommonEvents.dispatchEvent(CommonEvents.CURRENCY_CHANGED, this.activeCurrency);

        if (this.defaultMode) {
            window.location.reload();
        }
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
