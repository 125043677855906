import {Component, Input} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {FuseCardComponent} from "../../../../../@fuse/components/card";
import {RouterLink} from "@angular/router";
import {ItemListItemResponse} from "@app/model/item";
import {CurrencyConverterPipe} from "@moodeon-commons/pipe/currency-converter.pipe";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {FavouriteService} from "@app/service/favourite.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CartService} from "@app/service/cart.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CartItemRequest} from "@app/model/cart-item";
import {AlertService} from "@moodeon-commons/service/alert.service";
import {MediaWatcherService} from "../../../../../@fuse/services/media-watcher";
import {KeycloakService} from "keycloak-angular";
import {CartUtil} from "@app/modules/cart/CartUtil";
import {MatDialog} from "@angular/material/dialog";
import {SelectVariantPopUpComponent} from "@app/modules/item/select-variant-pop-up/select-variant-pop-up.component";

@Component({
    selector: 'thumb-type-product-card',
    standalone: true,
    imports: [CommonModule, ButtonComponent, FuseCardComponent, RouterLink, CurrencyConverterPipe, TranslocoModule,
        NgOptimizedImage, MatButtonModule, MatIconModule, MatFormFieldModule],
    templateUrl: './product-card-thumbnail.component.html',
    styles: []
})
export class ProductCardThumbnailComponent extends BaseComponent {
    @Input()
    item: ItemListItemResponse;

    protected readonly ImageUtils = ImageUtils;
    isSmallScreen: boolean;
    authenticatedMode = false;

    constructor(private favouriteService: FavouriteService, private cartService: CartService,
                private keycloakService: KeycloakService, private dialog: MatDialog,
                private _alertService: AlertService, private mediaWatcherService: MediaWatcherService) {
        super();

        this.mediaWatcherService.onScreenSizeChange$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((screenSize) => {
                this.isSmallScreen = screenSize.factor < 2;
            });

        this.authenticatedMode = this.keycloakService.getKeycloakInstance().authenticated;
    }

    toggleFavourite() {
        if (this.item.favourite) {
            this.removeFromFavourites();
        } else {
            this.addToFavourites();
        }
    }

    addToFavourites() {
        if (this.authenticatedMode) {
            this.favouriteService.create({itemId: this.item?.id}).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                    this.item.favourite = true;
                    CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, response.payload.id);
                });
        } else {
            const favourites = LocalStorageHandler.FAVOURITES.get([]);
            favourites.push(this.item?.id);
            LocalStorageHandler.FAVOURITES.set(favourites);
            this.item.favourite = true;
            CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, this.item?.id);
        }
    }

    removeFromFavourites() {
        if (this.authenticatedMode) {
            this.favouriteService.delete(this.item?.id).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                    this.item.favourite = false;
                    CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, this.item?.id);
                });
        } else {
            const favourites = LocalStorageHandler.FAVOURITES.get([]);
            const index = favourites.indexOf(this.item.id);
            if (index > -1) {
                favourites.splice(index, 1);
                LocalStorageHandler.FAVOURITES.set(favourites);
                this.item.favourite = false;
                CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, this.item?.id);
            }
        }
    }

    addToCart() {
        if (this.item.hasVariants) {
            const dialogRef = this.dialog.open(SelectVariantPopUpComponent, {data: {parentItemId: this.item?.id}});

            dialogRef.afterClosed().subscribe((selectedItemId: number) => {
                if (selectedItemId > 0) {
                    this.addItemToCart({itemId: selectedItemId, quantity: 1} as CartItemRequest);
                }
            });
        } else {
            this.addItemToCart({itemId: this.item?.id, quantity: 1} as CartItemRequest);
        }
    }

    private addItemToCart(cartItemRequest: CartItemRequest) {
        if (this.authenticatedMode) {
            this.cartService.addItemToCart(cartItemRequest).pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                    CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED, response.payload.id);
                    this._alertService.showSuccessAlert(translate('item.itemAddedToCart'));
                });
        } else {
            CartUtil.addItem(cartItemRequest);
            this._alertService.showSuccessAlert(translate('item.itemAddedToCart'));
        }
    }
}
