import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {TranslocoModule} from "@ngneat/transloco";
import {MatDialogModule} from "@angular/material/dialog";

@Component({
    selector: 'mdo-dialog-footer',
    standalone: true,
    imports: [CommonModule, ButtonComponent, TranslocoModule, MatDialogModule],
    templateUrl: './dialog-footer.component.html',
    styles: []
})
export class DialogFooterComponent {
    @HostBinding('class')
    public class = 'overflow-y-visible';

    @Input()
    showSubmitButton = true;

    @Input()
    showDismissButton = true;

    @Input()
    readOnly: boolean;

    @Input()
    submitButtonLabel = 'action.save';

    @Input()
    dismissButtonLabel = 'action.cancel';

    @Output()
    dismissButtonClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    submitButtonCLicked: EventEmitter<any> = new EventEmitter<any>();
}
