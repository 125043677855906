<div class="rounded-xl group">
    <div class="flex bg-primary rounded-xl">
        <a (click)="onClicked(notification)" *ngIf="hasLink(notification)" [routerLink]="[getRouterLink(notification)]"
           class="flex flex-auto p-4 cursor-pointer rounded-xl">
            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
        </a>

        <ng-container *ngIf="!hasLink(notification)">
            <div class="flex flex-auto py-3 pl-3 rounded-xl">
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
            </div>
        </ng-container>
    </div>

    <div class="absolute mt-2 right-0 top-0 z-10">
        <button (click)="$event.stopImmediatePropagation(); dismiss()" [matTooltip]="'Dismiss'"
                class="w-8 h-8 min-h-8 sm:hidden sm:group-hover:block mr-2" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="icon-size-4 text-white"></mat-icon>
        </button>
    </div>

    <ng-template #notificationContent>
        <ng-container *ngIf="notification.image">
            <img [alt]="'Notification image'" [src]="notification.image"
                 class="shrink-0 w-8 h-8 mr-4 rounded-xl overflow-hidden object-cover object-center">
        </ng-container>

        <div class="flex flex-col flex-auto max-w-80">
            <div class="flex justify-between">
                <ng-container *ngIf="notification.title">
                    <div [innerHTML]="notification.title" class="font-semibold line-clamp-1"></div>
                </ng-container>
            </div>
            <ng-container *ngIf="notification.description">
                <div [innerHTML]="notification.description" class="line-clamp-2"></div>
            </ng-container>
        </div>
    </ng-template>
</div>
