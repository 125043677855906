import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {takeUntil} from "rxjs";
import {FavouriteService} from "@app/service/favourite.service";
import {Router} from "@angular/router";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {KeycloakService} from "keycloak-angular";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

@Component({
    selector: 'favourite-panel-icon',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule],
    templateUrl: './favourites-panel-icon.component.html',
    styles: []
})
export class FavouritesPanelIconComponent extends BaseComponent implements OnInit {
    favouritesCount: number = 0;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private keycloakService: KeycloakService,
                private favouriteService: FavouriteService, private router: Router) {
        super();
        window.addEventListener(CommonEvents.FAVOURITES_UPDATED, (event: CustomEvent<string>) => {
            this.loadCount();
        });
    }

    ngOnInit(): void {
        this.loadCount();
    }

    loadCount() {
        if (this.keycloakService.getKeycloakInstance().authenticated) {
            this.favouriteService.getFavouriteCount().pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                    this.favouritesCount = response.payload?.count;
                    this._changeDetectorRef.markForCheck();
                });
        } else {
            this.favouritesCount = LocalStorageHandler.FAVOURITES.get([]).length;
        }
    }

    gotoFavourites() {
        this.router.navigateByUrl('/favourites');
    }
}
