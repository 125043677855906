import {Injectable} from '@angular/core';
import {CustomerService} from "@app/service/customer.service";
import {FavouriteService} from "@app/service/favourite.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CartService} from "@app/service/cart.service";
import {CartUtil} from "@app/modules/cart/CartUtil";

@Injectable({
    providedIn: 'root'
})
export class LoginInitService {

    constructor(private customerService: CustomerService, private favouriteService: FavouriteService, private cartService: CartService) {
    }

    async init(): Promise<any> {
        await this.customerService.getCurrentUser(true, true).toPromise();

        const favouriteIds = LocalStorageHandler.FAVOURITES.get([]);
        if (favouriteIds.length > 0) {
            const response = await this.favouriteService.syncFavourites(favouriteIds).toPromise();
            if (response.code == 1000) {
                LocalStorageHandler.FAVOURITES.remove();
            }
        }

        const cart = CartUtil.getCart();
        if (cart && cart.synced) {
            LocalStorageHandler.CART.remove();
        } else if (cart && !cart.synced && cart?.items?.length > 0) {
            const response = await this.cartService.createCart(cart).toPromise();
            if (response.code == 1000) {
                LocalStorageHandler.CART.remove();
            }
        }

        return new Promise<boolean>(resolve => {
            resolve(true);
        })
    }
}
