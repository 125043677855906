import {APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, inject, Provider} from '@angular/core';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoModule,
    TranslocoService
} from '@ngneat/transloco';
import {TranslocoHttpLoader} from 'app/core/transloco/transloco.http-loader';
import {TranslationHandler} from "@moodeon-commons/handler/translation-handler";

export const provideTransloco = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(TranslocoModule),
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [TranslationHandler.DEFAULT_LANGUAGE],
                defaultLang: TranslationHandler.DEFAULT_LANGUAGE,
                reRenderOnLangChange: true,
                prodMode: true
            }),
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);
                return () => translocoService.load(defaultLang).toPromise();
            },
            multi: true,
        }
    ];
};
