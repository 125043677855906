import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from "@moodeon-commons/component/icon/icon.component";
import {BaseButtonComponent} from "@moodeon-commons/component/base-button";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'mdo-a-button',
    standalone: true,
    imports: [CommonModule, IconComponent, MatButtonModule, MatInputModule, RouterLink],
    templateUrl: './a-button.component.html',
    styles: []
})
export class AButtonComponent extends BaseButtonComponent {
    @Input()
    routerLink: string;

    @Input()
    iconColor = 'white';
}
