import {BaseFilter} from "@moodeon-commons/model/common";
import {BaseModel} from "@moodeon-commons/model/base-model";
import {CartItemRequest, CartItemResponse} from "@app/model/cart-item";
import {PickUpDetailsRequest, PickUpDetailsResponse} from "@app/model/pick-up-details";
import {DineInDetailsRequest, DineInDetailsResponse} from "@app/model/dine-in-details";
import {DeliveryDetailsRequest} from "@app/model/delivery-details";
import {BranchListItemResponse} from "@app/model/branch";
import {AddressResponse} from "@app/model/address";
import {OrderCustomer} from "@app/model/order";

export class CartListItemResponse extends BaseModel {
    customer: number;
    items: string;
}

export class CartRequest extends BaseModel {
    synced: boolean;
    customerId: number;
    items: CartItemRequest[];
    serviceType: ServiceType;
    customerInfo: any;
    notes: string;
    deliveryDetails: DeliveryDetailsRequest;
    pickUpDetails: PickUpDetailsRequest;
    dineInDetails: DineInDetailsRequest;

    static empty(): CartRequest {
        const cart = new CartRequest();
        cart.items = [];
        return cart;
    }
}

export class CartResponse extends BaseModel {
    branch: BranchListItemResponse;
    customer: OrderCustomer;
    items: CartItemResponse[];
    subTotal: number;
    serviceFee: number;
    tax: number;
    total: number;
    totalItems: number;
    serviceType: ServiceType;
    notes: string;
    scheduled: boolean;
    deliveryDetails: DeliveryDetailsResponse;
    pickUpDetails: PickUpDetailsResponse;
    dineInDetails: DineInDetailsResponse;
    storeOpen: boolean;
}

export class CartFilter extends BaseFilter {
    customerId: number;
}

export class DeliveryDetailsResponse extends BaseModel {
    includeUtensils: boolean;
    fee: number;
    address: AddressResponse;
    addressId: number;
    saveAddress: boolean;
    deliveryMethod: string;
    deliveryDateTimeRequested: any;
    notes: string;
    status: string;
}

export class CartServiceDetailsUpdateRequest extends BaseModel {
    serviceType: ServiceType;
    notes: string;
    deliveryDetails: DeliveryDetailsRequest;
    pickUpDetails: PickUpDetailsRequest;
    dineInDetails: DineInDetailsRequest;
}

export type ServiceType = 'PICK_UP' | 'DINE_IN' | 'DELIVERY';
