<mdo-dialog-container widthClasses="max-w-540 sm:min-w-80 md:min-w-140 lg:min-w-160">
    <mdo-dialog-header [title]="'item.selectVariantHeader' | transloco"></mdo-dialog-header>
    <mdo-dialog-content>
        <div *ngIf="availableItems" class="mt-4">
            <div *ngFor="let item of availableItems" class="flex flex-col">
                <div class="flex">
                    <div class="h-10 sm:h-12 w-10 sm:w-12">
                        <img [src]="ImageUtils.generateImagePath(item.image)" alt="Item image"
                             class="object-cover h-10 sm:h-12 w-10 sm:w-12 rounded">
                    </div>
                    <div class="flex flex-col grow mx-4 self-center">
                        <span class="font-medium text-lg">{{ item.variantName }}</span>
                        <span class="text-secondary">{{ item.sku }}</span>
                    </div>
                    <div class="flex self-center">
                        <mdo-button (buttonClick)="dialogRef.close(item.id)" [disabled]="item.stockCount != null && item.stockCount == 0"
                                    color="primary"
                                    size="medium"
                                    type="flat">{{ item.basePrice | convertCurrency }}
                        </mdo-button>
                    </div>
                </div>
                <mdo-separator [margin]="2"></mdo-separator>
            </div>
        </div>
    </mdo-dialog-content>
    <mdo-dialog-footer (dismissButtonClicked)="dialogRef.close()" [showSubmitButton]="false"></mdo-dialog-footer>
</mdo-dialog-container>
