<div
    (click)="settingsDrawer.toggle()"
    [class.lg:right-0]="config.layout === 'centered' || config.layout === 'material'"
    class="settings-cog fixed flex flex-col items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-red-600 bg-opacity-90 print:hidden"
    style="top: 275px">
    <mat-icon
        [svgIcon]="'heroicons_solid:cog-8-tooth'"
        class="icon-size-5 text-white animate-spin-slow mt-1"></mat-icon>
    <span class="text-on-warn">{{ screenSize }}</span>
</div>

<fuse-drawer
    #settingsDrawer
    [mode]="'over'"
    [name]="'settingsDrawer'"
    [position]="'right'"
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <mat-icon
                [svgIcon]="'heroicons_solid:cog-8-tooth'"
                class="icon-size-7 text-current"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div>
            <button
                (click)="settingsDrawer.close()"
                class="ml-auto"
                mat-icon-button>
                <mat-icon
                    [svgIcon]="'heroicons_outline:x-mark'"
                    class="text-current"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">

            <!-- Theme -->
            <div class="text-md font-semibold text-secondary">THEME</div>
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-6">
                <ng-container *ngFor="let theme of config.themes">
                    <div
                        (click)="setTheme(theme.id)"
                        [class.ring-2]="config.theme === theme.id"
                        [ngClass]="theme.id"
                        class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover">
                        <div
                            class="flex-0 w-3 h-3 rounded-full bg-primary"
                        ></div>
                        <div
                            [class.text-secondary]="config.theme !== theme.id"
                            class="ml-2.5 font-medium leading-5 truncate">
                            {{ theme.name }}
                        </div>
                    </div>
                </ng-container>
            </div>

            <hr class="my-8">

            <!-- Scheme -->
            <div class="text-md font-semibold text-secondary">SCHEME</div>
            <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
                <!-- Auto -->
                <div
                    (click)="setScheme('auto')"
                    [class.ring-2]="config.scheme === 'auto'"
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query.">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:bolt'"
                            class="icon-size-5"></mat-icon>
                    </div>
                    <div
                        [class.text-secondary]="config.scheme !== 'auto'"
                        class="flex items-center ml-2 font-medium leading-5">
                        Auto
                    </div>
                </div>
                <!-- Dark -->
                <div
                    (click)="setScheme('dark')"
                    [class.ring-2]="config.scheme === 'dark'"
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:moon'"
                            class="icon-size-5"></mat-icon>
                    </div>
                    <div
                        [class.text-secondary]="config.scheme !== 'dark'"
                        class="flex items-center ml-2 font-medium leading-5">
                        Dark
                    </div>
                </div>
                <!-- Light -->
                <div
                    (click)="setScheme('light')"
                    [class.ring-2]="config.scheme === 'light'"
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            [svgIcon]="'heroicons_solid:sun'"
                            class="icon-size-5"></mat-icon>
                    </div>
                    <div
                        [class.text-secondary]="config.scheme !== 'light'"
                        class="flex items-center ml-2 font-medium leading-5">
                        Light
                    </div>
                </div>
            </div>
        </div>
    </div>
</fuse-drawer>
