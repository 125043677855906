<moodeon-card class="flex flex-col w-full">
    <div class="relative">
        <mdo-button class="absolute flex flex-col top-0 right-0 p-1 sm:p-2 z-10"
                    (buttonClick)="toggleFavourite()"
                    [iconColor]="item?.favourite ? '#dc2626e6' : null" icon="heart" iconLib="heroicons_solid"
                    type="icon"></mdo-button>
        <div class="h-30 sm:h-36">
            <img [src]="ImageUtils.generateImagePath(item.image)" alt="Item image"
                 class="object-cover h-30 sm:h-36">
        </div>
    </div>
    <div class="m-3">
        <div class="flex w-full justify-between">
            <div class="flex items-center">
                <ng-container *ngIf="item.rating > 0">
                    <span>{{ item.rating }}</span>
                    <mat-icon class="icon-size-4" style="color: #f2a63f;" svgIcon="heroicons_solid:star"></mat-icon>
                </ng-container>
            </div>
            <div>
                <span *ngIf="item.maxPrice > item.minPrice" class="mr-1 font-medium text-sm md:text-md"
                      style="color: #0db50d">{{ 'item.from' | transloco }}</span>
                <span class="text-lg md:text-xl font-semibold"
                      style="color: #0db50d">{{ item.minPrice | convertCurrency }}</span>
            </div>
        </div>
        <span class="flex w-full text-md sm:text-lg leading-tight mt-1">{{ item.name }}</span>
        <span class="flex w-full text-sm sm:text-md leading-tight text-secondary">{{ item.category }}</span>
        <div class="flex mt-2 md:mt-4 gap-2">
            <button (click)="addToCart()" [ngClass]="{'p-2 min-h-8 h-8': isSmallScreen, 'p-4': !isSmallScreen}"
                    class="rounded-md min-w-4 font-medium" color="primary"
                    mat-flat-button>
                <mat-icon [color]="'primary'" class="icon-size-4" matIconPrefix matPrefix
                          svgIcon="mat_outline:shopping_cart"></mat-icon>
            </button>
            <mdo-button [routerLink]="['../', 'items', item.slug]" buttonClass="w-full" class="grow" color="primary"
                        [size]="isSmallScreen ? 'medium' : 'large'"
                        type="flat">{{ 'item.button.viewItem' | transloco }}
            </mdo-button>
        </div>
    </div>
</moodeon-card>
