import {provideHttpClient} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core';
import {LuxonDateAdapter} from '@angular/material-luxon-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from '@angular/router';
import {provideFuse} from '@fuse';
import {appRoutes} from 'app/app.routes';
import {provideAuth} from 'app/core/auth/auth.provider';
import {provideIcons} from 'app/core/icons/icons.provider';
import {provideTransloco} from 'app/core/transloco/transloco.provider';
import {mockApiServices} from 'app/mock-api';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CurrencyPipe} from "@angular/common";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initKeycloak} from "@app/core/auth/keycloak-init.factory";
import {LoginInitService} from "@app/service/login-init.service";

const appInitializerFn = (keycloak: KeycloakService, loginInitService: LoginInitService) =>
    (): Promise<void> => new Promise<void>(resolved => {
        initKeycloak(keycloak, loginInitService).then(() => resolved());
    });

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(MatSnackBarModule),
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        KeycloakAngularModule,
        KeycloakService,
        LoginInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [KeycloakService, LoginInitService],
        },

        // Transloco Config
        provideTransloco(),

        CurrencyPipe,

        // Fuse
        provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'modern',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
