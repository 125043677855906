<mdo-page-container *transloco="let t; read:'notification'">
    <mdo-page-header [breadcrumbItems]="[]" [component]="component" pageHeader>
    </mdo-page-header>

    <div class="w-2/3 m-4">
        <ng-container *ngFor="let notification of items; trackBy: trackByFn">
            <div
                [ngClass]="{'unread': !notification.read}"
                class="flex group bg-white hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 mb-1">

                <a (click)="onClicked(notification)" *ngIf="hasLink(notification)"
                   [routerLink]="getRouterLink(notification)"
                   class="flex flex-auto py-5 pl-6 cursor-pointer">
                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                </a>

                <!-- Notification without a link -->
                <ng-container *ngIf="!hasLink(notification)">
                    <div class="flex flex-auto py-5 pl-6">
                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </div>
                </ng-container>

                <!-- Actions -->
                <div class="relative flex flex-col my-5 mr-6 ml-2">
                    <!-- Indicator -->
                    <button (click)="toggleRead(notification)"
                            [matTooltip]="notification.read ? t('markAsUnread') : t('markAsRead')"
                            class="w-6 h-6 min-h-6" mat-icon-button>
                            <span
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.read,
                                            'bg-primary': !notification.read}" class="w-2 h-2 rounded-full"></span>
                    </button>
                    <!-- Remove -->
                    <button (click)="delete(notification)" [matTooltip]="t('remove')"
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100 mt-2" mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_solid:x-mark'" class="icon-size-4"></mat-icon>
                    </button>
                </div>
            </div>

            <ng-template #notificationContent>
                <ng-container *ngIf="notification.image">
                    <img [alt]="'Notification image'" [src]="notification.image"
                         class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center">
                </ng-container>

                <div class="flex flex-col flex-auto">
                    <ng-container *ngIf="notification.title">
                        <div [innerHTML]="notification.title" class="font-semibold line-clamp-1"></div>
                    </ng-container>
                    <ng-container *ngIf="notification.description">
                        <div [innerHTML]="notification.description" class="line-clamp-2"></div>
                    </ng-container>
                    <div class="mt-2 text-sm leading-none text-secondary">
                        {{ notification.createdAt | date:'MMM dd, h:mm a' }}
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <div (click)="loadMore()" *ngIf="!noMoreNotifications"
             [class.cursor-pointer]="!isLoading" class="flex p-4 justify-center font-medium hover:bg-gray-50">
            <span>{{ isLoading ? t('loading') : t('loadMore') }}</span>
        </div>

        <!-- No notifications -->
        <ng-container *ngIf="!items || !items.length">
            <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                <div
                    class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                    <mat-icon [svgIcon]="'heroicons_outline:bell'"
                              class="text-primary-700 dark:text-primary-50"></mat-icon>
                </div>
                <div class="mt-5 text-2xl font-semibold tracking-tight">{{ t('noNotificationsHeader') }}</div>
                <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ t('noNotificationsMsg') }}</div>
            </div>
        </ng-container>
    </div>
</mdo-page-container>
