import {CartRequest} from "@app/model/cart";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {CartItemRequest} from "@app/model/cart-item";

export class CartUtil {
    public static getNextCartItemId(cart: CartRequest): number {
        return cart.items.length > 0 ? Math.max(...cart.items.map(value => value.id)) + 1 : 1;
    }

    public static getCart(): CartRequest {
        return LocalStorageHandler.CART.get(CartRequest.empty());
    }

    public static getCartItemById(cartItemId: number): CartItemRequest {
        const cart = CartUtil.getCart();
        return cart.items.find((item) => item.id === cartItemId);
    }

    public static addItem(cartItem: CartItemRequest) {
        const cart = CartUtil.getCart();
        cartItem.id = CartUtil.getNextCartItemId(cart);
        cart.items.push(cartItem);
        LocalStorageHandler.CART.set(cart);
        CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED, cartItem.id);
    }

    public static replaceItem(cartItemToReplace: CartItemRequest) {
        const cart = CartUtil.getCart();
        const index = cart.items.findIndex(cartItem => cartItem.id === cartItemToReplace.id);
        if (index < 0) {
            this.addItem(cartItemToReplace);
        } else {
            cart.items[index] = cartItemToReplace;
            LocalStorageHandler.CART.set(cart);
        }
        CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED, cartItemToReplace.id);
    }

    static removeItem(cartItemId: number): CartRequest {
        const cart = CartUtil.getCart();
        const index = cart.items.findIndex(cartItem => cartItem.id === cartItemId);
        if (index > -1) {
            cart.items.splice(index, 1);
            LocalStorageHandler.CART.set(cart);
        }
        return cart;
    }

    static clearCart() {
        LocalStorageHandler.CART.remove();
        CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED);
    }

    static updateCart(cart: CartRequest) {
        LocalStorageHandler.CART.set(cart);
        CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED);
    }
}
