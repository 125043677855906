import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {ItemListItemResponse} from "@app/model/item";
import {FavouriteRequest} from "@app/model/favourite";

@Injectable({
    providedIn: 'root'
})
export class FavouriteService extends BaseCrudService<ItemListItemResponse, FavouriteRequest, any> {
    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "favourites";
    }

    getFavouriteCount(): Observable<ApiResponse<any>> {
        return this._httpClient.get<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'count'));
    }

    getFavouritesByIds(ids: number[]): Observable<ApiResponse<any>> {
        return this._httpClient.get<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'by-ids'), {params: {ids: ids}});
    }

    syncFavourites(ids: number[]): Observable<ApiResponse<any>> {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, this.getContextPath(), 'sync'), ids);
    }
}
