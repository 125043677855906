import {Injectable} from '@angular/core';
import {BaseCrudService} from "@moodeon-commons/service/base-crud-service";
import {BranchAvailability, BranchListItemResponse, BranchResponse} from "@app/model/branch";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";

@Injectable({
    providedIn: 'root'
})
export class BranchService extends BaseCrudService<BranchListItemResponse, BranchResponse, BranchResponse> {

    constructor(private _httpClient: HttpClient) {
        super(_httpClient);
    }

    getContextPath(): string {
        return "branches";
    }

    getBranchAvailability(branchId: number): Observable<ApiResponse<BranchAvailability>> {
        return this._httpClient.get<ApiResponse<BranchAvailability>>(this.concatUrl(this.baseUrl, this.getContextPath(), branchId, 'availability'));
    }
}
