/* eslint-disable */
import {FuseNavigationItem} from '@fuse/components/navigation';

export const navigation: FuseNavigationItem[] = [
    {
        id: 'home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home'
    },
    {
        id: 'items',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/items'
    },
    {
        id: 'orders',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-document-list',
        link: '/orders',
        authRequired: true
    }
];
