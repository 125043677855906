<div class="flex flex-wrap items-center font-medium">
    <ng-container *ngFor="let breadcrumbItem of breadcrumbItems; let i = index">
        <div>
            <a [ngClass]="i === breadcrumbItems.length - 1 ? 'truncate text-gray-500' : 'text-primary-500'"
               [routerLink]="breadcrumbItem.routerLink"
               class="whitespace-nowrap">
                <span class="flex items-center" style="gap: 5px">
                    <mdo-icon *ngIf="breadcrumbItem.icon" [className]="'icon-size-4'" [iconLib]="breadcrumbItem.iconLib"
                              [icon]="breadcrumbItem.icon"></mdo-icon>
                    <span
                        *ngIf="breadcrumbItem.label || breadcrumbItem.title">{{ breadcrumbItem.label ? (breadcrumbItem.label | transloco) : breadcrumbItem.title }}</span>
                </span>
            </a>
        </div>
        <div *ngIf="i != breadcrumbItems.length - 1" class="flex items-center whitespace-nowrap">
            <mdo-icon className="icon-size-5 text-secondary" icon="chevron-right"
                      iconLib="heroicons_mini"></mdo-icon>
        </div>
    </ng-container>
</div>
